import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Axios from 'axios';

const Contact = () => {
  const [contentData, setContentData] = useState({
    title: '',
    content: '',
    phone: '',
    extraContent: '',
    image: '',
    heading_1: '',
    heading_2: '',
    heading_3: '',
    heading_4: ''
  });
  
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    topic: '',
    regolamento_generale_sulla_protezione_dei_dati: false,
    offers_and_communications: false
  });
  
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchContentData();
  }, []);

  const fetchContentData = async () => {
    try {
      const response = await fetch('https://dayoffice24.it/backend/api/contact-us/page-data');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setContentData(data.data);
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    } catch (error) {
      console.error('Error fetching content data:', error);
      setError('Failed to load page content. Please refresh the page.');
    }
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    // Clear errors when user starts typing
    if (error) setError('');
    if (successMessage) setSuccessMessage('');
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^\+?[0-9\s-]{10,}$/;
    return re.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation checks
    const errors = [];
    
    if (!formData.name.trim()) errors.push('Il nome è obbligatorio');
    if (!formData.surname.trim()) errors.push('Il cognome è obbligatorio');
    if (!formData.email.trim()) errors.push('Email is required');
    else if (!validateEmail(formData.email)) errors.push('Si prega di inserire un indirizzo email valido');
    if (!formData.phone.trim()) errors.push('Il numero di telefono è obbligatorio');
    else if (!validatePhone(formData.phone)) errors.push('Inserisci un numero di telefono valido');
    if (!formData.topic) errors.push('Seleziona un argomento');
    if (!formData.regolamento_generale_sulla_protezione_dei_dati) errors.push("È necessario accettare l'informativa sulla privacy");

    if (errors.length > 0) {
      setError(errors.join('\n'));
      setSuccessMessage('');
      return;
    }

    try {
      const response = await Axios.post('https://dayoffice24.it/backend/api/contact-us', formData);
      
      if (response.data.success) {
        setSuccessMessage('Your inquiry has been submitted successfully!');
        setError('');
        // Reset form
        setFormData({
          name: '',
          surname: '',
          email: '',
          phone: '',
          topic: '',
          regolamento_generale_sulla_protezione_dei_dati: false,
          offers_and_communications: false
        });
      } else {
        throw new Error(response.data.message || 'Submission failed');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred. Please try again later.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="main_wrap">
      <Header />
      <div className="content-wrapper">
        <div className="breadcrumb-wrap bg-f br-2">
          <div className="container">
            <div className="breadcrumb-title text-center">
              <h2>Contattaci</h2>
              <ul className="breadcrumb-menu list-style">
                <li><a href="index.html">Casa</a></li>
                <li>Contattaci</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="business-section ptb-100 wer">
          <div className="container">
            <div className="row g-4">
              <div className="col-md-6">
                <div className="business-card">
                  <img
                    src={`https://dayoffice24.it/backend/images/${contentData.image}`}
                    className="business-card-img"
                    alt="Private Office"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="card custom-card">
                  <div className="card-body">
                    <h2 className="ci-title">{contentData.title}</h2>
                    <p>{contentData.content}</p>
                    <ul className="list-unstyled fgew">
  {contentData.headings && Object.values(contentData.headings).map((heading, index) => (
    <li key={index}>
      <img src={`https://dayoffice24.it/backend/images/${heading.image}`} alt={heading.text} />
      <strong>{heading.text}</strong>
    </li>
  ))}
</ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='contact-form-sc ptb-100'>
        <div className="container form-container login-content">
          <div className="row">
            <div className="col-md-6 form-section">
              <h1 className="mb-4">Dayoffice24 a tua disposizione!</h1>
              <h6 className='mb-1'>Il nostro Team è a tua disposizione per ogni esigenza</h6>
              <p>{contentData.extra_content_2}</p>
              
              <form className="user-form" onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <select
                        id="topic"
                        name="topic"
                        className="form-control"
                        value={formData.topic}
                        onChange={handleFormChange}
                        required
                      >
                        <option value="">Quali soluzioni ti interessano?</option>
                        <option value="day-office">Day Office</option>
                        <option value="sale-riunioni">Sale Riunioni/ Eventi</option>
                        <option value="diventare-host">Diventare Host</option>
                        <option value="coworking">Coworking</option>
                        <option value="altro">Altro</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Nome"
                        value={formData.name}
                        onChange={handleFormChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <input
                        id="surname"
                        name="surname"
                        type="text"
                        className="form-control"
                        placeholder="Cognome"
                        value={formData.surname}
                        onChange={handleFormChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleFormChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <input
                        id="phone"
                        name="phone"
                        type="tel"
                        className="form-control"
                        placeholder="Telefono"
                        value={formData.phone}
                        onChange={handleFormChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-12">
                    <div className="checkbox style3 mb-3">
                      <input
                        type="checkbox"
                        id="regolamento_generale_sulla_protezione_dei_dati"
                        name="regolamento_generale_sulla_protezione_dei_dati"
                        checked={formData.regolamento_generale_sulla_protezione_dei_dati}
                        onChange={handleFormChange}
                        required
                      />
                      <label htmlFor="regolamento_generale_sulla_protezione_dei_dati">
                        Compilando questo modulo autorizzi il trattamento e la gestione dei dati immessi secondo quanto stabilisce il nuovo Regolamento UE 2016/679 RGDP "Regolamento generale sulla protezione dei dati".
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 col-12">
                    <div className="checkbox style3 mb-3">
                      <input
                        type="checkbox"
                        id="offers_and_communications"
                        name="offers_and_communications"
                        checked={formData.offers_and_communications}
                        onChange={handleFormChange}
                      />
                      <label htmlFor="offers_and_communications">
                      Desidero iscrivermi alla Newsletter di Dayoffice24
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <p className="small-text">
                    Inviando questo modulo accetti la nostra <a href="#" className="link">privacy policy</a>.
                    </p>
                    <button type="submit" className="btn style1">Invia Ora</button>
                  </div>
                </div>
              </form>

              {error && (
                <div className="alert alert-danger mt-3">
                  {error.split('\n').map((err, index) => (
                    <div key={index}>{err}</div>
                  ))}
                </div>
              )}
              {successMessage && (
                <div className="alert alert-success mt-3">{successMessage}</div>
              )}
            </div>

            <div className="col-md-6 info-section">
                <div className="info-box">
                  <h5>Contatta la nostra assistenza</h5>
                  <p>{contentData.extra_content}</p>
                  <p className='sqw'>
                    <i className="ri-phone-fill"></i> {contentData.phone}
                  </p>
                </div>
                <div className="info-box">
                  <h5>Prenota i Nostri Spazi Direttamente da Qui
                  </h5>
                  <p>Scegli la tipologia di spazio di cui hai bisogno.</p>
                  <div className='btn-scf'>
                    <a href='https://dayoffice24.it/category/65fd1b02358f0d7ac102aa5f' className="btn style3"> Prenota un Dayoffce <i className="ri-slideshow-line"></i></a>
                    <a href='https://dayoffice24.it/category/65fd1b02358f0d7ac102aa60' className="btn style3">Prenota un Coworking
                     <i className="ri-building-line"></i></a>
                    <a href='https://dayoffice24.it/category/65fd1b02358f0d7ac102aa61' className="btn style3">Prenota una Sala Riunione
                    <i className="ri-folder-line"></i></a>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;