import React from 'react';
import { BrowserRouter as Router,Routes,Route,Navigate} from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Category from './pages/Category';
import Faq from './pages/Faq';
import HelpCenter from './pages/HelpCenter';
import HelpCenterView from './pages/HelpCenterView';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cities from './pages/Cities';
import BecomeHost from './pages/BecomeHost';
import Checkout from './pages/Checkout';
import ForgotPassword from './pages/ForgotPassword';
import PropertySearch from './pages/PropertySearch';
import PropertyView from './pages/PropertyView';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Chatboat from './pages/Chatboat';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import EditProfile from './pages/EditProfile';
import ChangePassword from './pages/ChangePassword';
import AddTestimonials from './pages/AddTestimonials';
import AddPropertyTestimonials from './pages/AddPropertyTestimonials';
import ExploreProperty from './pages/ExploreProperty';
import Payment from './pages/Payment';
import OrderHistory from './pages/OrderHistory';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFailure from './pages/PaymentFailure';
import OAuthCallback from './pages/OAuthCallback';
import RedirectURL from './pages/RedirectURL';
import NotFound from './pages/NotFound';


class App extends React.Component {



render() {
    return (
    <>
      <Router>
            <Routes> 
                  <Route exact path="/" element={<Home/>} ></Route>
                  <Route exact path="/About" element={<About/>} ></Route>
                  <Route exact path="/Contact" element={<Contact/>} ></Route>
                  <Route exact path="/Category/:id" element={<Category/>} ></Route>
                  <Route exact path="/Faq" element={<Faq/>} ></Route>
                  <Route exact path="/HelpCenter" element={<HelpCenter/>} ></Route>
                  <Route exact path="/HelpCenterView/:id" element={<HelpCenterView/>} ></Route>
                  <Route exact path="/Privacy" element={<Privacy/>} ></Route>
                  <Route exact path="/Terms" element={<Terms/>} ></Route>
                  <Route exact path="/Cities/:cityId" element={<Cities/>} ></Route>
                  <Route exact path="/BecomeHost" element={<BecomeHost/>} ></Route>
                  <Route exact path="/Checkout" element={<Checkout/>} ></Route>
                  <Route exact path="/ForgotPassword" element={<ForgotPassword/>} ></Route>
                  <Route exact path="/PropertySearch" element={<PropertySearch/>} ></Route>
                  <Route exact path="/PropertyView/:id" element={<PropertyView/>} ></Route>
                  <Route exact path="/Register" element={<Register/>} ></Route>
                  <Route exact path="/Profile" element={<Profile/>} ></Route> 
                  <Route exact path="/Chatboat" element={<Chatboat/>} ></Route>
                  <Route exact path="/Login" element={<Login/>} ></Route>
                  <Route exact path="/ResetPassword" element={<ResetPassword/>} ></Route>
                  <Route exact path="/EditProfile" element={<EditProfile/>} ></Route>
                  <Route path="/ChangePassword" element={<ChangePassword />} />
                  <Route path="/AddTestimonials" element={<AddTestimonials />} />
                  <Route path="/AddPropertyTestimonials/:propertyId" element={<AddPropertyTestimonials />} />
                  <Route path="/ExploreProperty" element={<ExploreProperty />} />
                  <Route path="/Payment" element={<Payment />} />
                  <Route path="/OrderHistory" element={<OrderHistory />} />   
                  <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
                  <Route path="/PaymentFailure" element={<PaymentFailure />} />
                  <Route path="https://dayoffice24.it/backend/api/auth/google-user/callback" component={OAuthCallback} />
                  <Route path="https://dayoffice24.it/backend/api/auth/linkedin-user/callback" component={OAuthCallback} />
                  <Route path="https://dayoffice24.it/backend/auth/twitter-user/callback" component={OAuthCallback} />
                  <Route path="/RedirectURL" element={<RedirectURL />} />
                  <Route path="/NotFound" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="/" />} />
            </Routes>
      </Router>
    </>
    );
  }
}

export default App;

