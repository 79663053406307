import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get('property_id');
  const [extraServices, setExtraServices] = useState([]);
  const [selectedExtraServices, setSelectedExtraServices] = useState([]);
  const [packageData, setPackageData] = useState(null);
  const startDateParam = queryParams.get('start_date');
  const endDateParam = queryParams.get('end_date');
  const numberOfGuests = queryParams.get('no_of_guest');
  const [selectedServicesDetails, setSelectedServicesDetails] = useState([]);
  const [propertyPricePerDay, setPropertyPricePerDay] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const startDate = new Date(startDateParam);
  const endDate = new Date(endDateParam);
  const [couponCode, setCouponCode] = useState('');
  const [couponData, setCouponData] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState('');
  const [taxData, setTaxData] = useState([]);
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [companyNameValue, setCompanyNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [mobileValue, setMobileValue] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [countryValue, setCountryValue] = useState('');
  const selectedServiceIds = selectedServicesDetails.map(service => service.serviceId);
  const [pecValue, setPecValue] = useState('');
  const [sdiValue, setSdiValue] = useState('');
  const [taxidValue, setTaxIDValue] = useState('');
  const [couponCodeId, setCouponIDValue] = useState('');
  const [specialRequest, setSpecialRequest] = useState('');


  useEffect(() => {
    // Fetch property and extra service data
    if (propertyId) {
      fetch(`https://dayoffice24.it/backend/api/booking/property/${propertyId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setPackageData(data.data);
            setPropertyPricePerDay(parseFloat(data.data.property.price));
            if (data.data.property.discount_check === "yes") {
              let discountedAmount = 0;
              if (data.data.property.discount_type === "amount") {
                discountedAmount = parseFloat(data.data.property.discount_percent_amount);
              } else if (data.data.property.discount_type === "percent") {
                discountedAmount = parseFloat(data.data.property.price) * calculateDurationInDays(startDate, endDate) * parseFloat(data.data.property.discount_percent_amount) / 100;
              }
              setDiscountAmount(discountedAmount);
            } else {
              setDiscountAmount(0);
            }
          } else {
            throw new Error('API returned an error:' + data.message);
          }
        })
        .catch((error) => {
          console.error('Error fetching package data:', error);
        });
    }

      fetch(`https://dayoffice24.it/backend/api/booking/extra-service/${propertyId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setExtraServices(data.data);
        } else {
          throw new Error('API returned an error:' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching extra services:', error);
      });
  }, [propertyId]);

  const handleExtraServiceChange = (serviceId) => {
    const service = extraServices.find(extraService => extraService.service_id === serviceId);
    if (service) {
      const updatedSelectedServices = selectedExtraServices.includes(serviceId)
        ? selectedExtraServices.filter(id => id !== serviceId)
        : [...selectedExtraServices, serviceId];
  
      setSelectedExtraServices(updatedSelectedServices);
  
      const updatedServicesDetails = updatedSelectedServices.map(id => {
        const selectedService = extraServices.find(extraService => extraService.service_id === id);
        return {
          serviceId: id,
          serviceName: selectedService.service.service_name,
          servicePrice: selectedService.service.price,
          extraServices: selectedService.service.extra_services, // Include extra services
        };
      });
      setSelectedServicesDetails(updatedServicesDetails);
    }
  };

const calculateDurationInDays = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1; // Add 1 to count the same day as one day
  return diffDays;
};


// Updated calculateTotalPriceAfterDiscountAndCoupon() function to include numberOfGuests
const calculateTotalPriceAfterDiscountAndCoupon = () => {
  const servicesTotal = selectedServicesDetails
    ? selectedServicesDetails.reduce((total, service) => {
        const totalServicePrice = parseFloat(service.servicePrice) * calculateDurationInDays(startDate, endDate);
        return total + totalServicePrice;
      }, 0)
    : 0; // Default to 0 if no services

  const propertyTotal = propertyPricePerDay * calculateDurationInDays(startDate, endDate) * numberOfGuests;
  const totalAmountBeforeDiscount = propertyTotal + servicesTotal;

  const totalAmountAfterDiscount = totalAmountBeforeDiscount - (discountAmount || 0); // Apply discount

  let totalAmountAfterCoupon = totalAmountAfterDiscount;

  if (couponData) {
    if (couponData.amount_type === 'amount') {
      totalAmountAfterCoupon -= parseFloat(couponData.amount);
    } else if (couponData.amount_type === 'percentage') {
      const percentageDiscount = (parseFloat(couponData.amount) / 100) * totalAmountAfterCoupon;
      totalAmountAfterCoupon -= percentageDiscount;
    }
  }

  return totalAmountAfterCoupon >= 0 ? totalAmountAfterCoupon : 0; // Ensure total is not negative
};



const applyCoupon = () => {
  console.log('Applying coupon...');
  if (couponCode) {
    console.log('Coupon code:', couponCode);
    fetch('https://dayoffice24.it/backend/api/booking/coupon', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ coupon_code: couponCode }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Coupon application response:', data);
        if (data.success) {
          setCouponApplied(true);
          setCouponData(data.data); // Assuming the coupon data is returned in the response
          setCouponError('');
        } else {
          setCouponApplied(false);
          setCouponError(data.message);
        }
      })
      .catch((error) => {
        console.error('Error applying coupon:', error);
        setCouponApplied(false);
        setCouponError('An error occurred while applying the coupon.');
      });
  }
};

// Calculate the total tax percentage based on the fetched tax data
  const calculateTotalTaxPercentage = () => {
    const totalTaxPercentage = taxData.reduce(
      (total, tax) => total + parseFloat(tax.tax_percent),
      0
    );
    return totalTaxPercentage / 100;
  };


  useEffect(() => {
    // Fetch tax data
    fetch('https://dayoffice24.it/backend/api/booking/tax')
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTaxData(data.data);
        } else {
          throw new Error('API returned an error:' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching tax data:', error);
      });
  }, []);


const [finalPaymentAmount, setFinalPaymentAmount] = useState(0);

const handlePayNowClick = (e) => {
  e.preventDefault();
  
  // Check if the user is logged in
  const usertoken = localStorage.getItem('token');

  const headers = {
    'Authorization': `Bearer ${usertoken}`,
    'Content-Type': 'application/json',
  };

  const payableAmount = (
    calculateTotalPriceAfterDiscountAndCoupon() *
    (1 + calculateTotalTaxPercentage())
  ).toFixed(0);

  setFinalPaymentAmount(payableAmount);

  const requestData = {
    property_id: propertyId,
    start_date: startDate,
    end_date: endDate,
    property_amount: propertyPricePerDay,
    discount_amount: discountAmount,
    service_id: selectedExtraServices.join(','),
    total_amount: calculateTotalPriceAfterDiscountAndCoupon().toFixed(0),
    coupon_id: couponData ? couponData._id : '',
    coupon_amount: couponAmount,
    tax_id: taxData.map(tax => tax._id).join(','),
    total_tax: calculateTotalTaxAmount().toFixed(0),
    first_name: firstNameValue,
    last_name: lastNameValue,
    company_name: companyNameValue,
    email: emailValue,
    mobile_no: mobileValue,
    address: addressValue,
    state: stateValue,
    country: countryValue,
    pec: pecValue,
    sdi: sdiValue,
    tax_id_code: taxidValue,
    final_total: payableAmount,
    guests: numberOfGuests,
    extra_service: {
      special_request: specialRequest, 
      selected_services: selectedServicesDetails.map(service => ({
        service_id: service.serviceId,
        service_name: service.serviceName,
        service_price: service.servicePrice,
      })),
    },
  };
  


  fetch('https://dayoffice24.it/backend/api/booking/store', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('API request failed with status: ' + response.status);
      }
    })
   .then((data) => {
        console.log('API Response:', data); // Log the entire API response
        if (data && data.status === 'Success' && data.booking) {
          const bookingId = data.booking._id;
          console.log('Booking ID:', bookingId); // Log the booking ID
          window.location.href = `/Payment?bookingId=${bookingId}`;
        } else {
          console.error('Booking not successful or booking data is missing.');
          // Handle any other error conditions here
        }
      })

    .catch((error) => {
      console.error('Error sending payment amount to backend:', error);
    });

};


// Define a function to calculate the total tax amount
const calculateTotalTaxAmount = () => {
  const vatTax = taxData.find(tax => tax.tax_type === 'VAT');
  const gstTax = taxData.find(tax => tax.tax_type === 'GST');

  const totalPrice = calculateTotalPriceAfterDiscountAndCoupon();
  const vatTaxAmount = vatTax ? (totalPrice * (vatTax.tax_percent / 100)) : 0;
  const gstTaxAmount = gstTax ? (totalPrice * (gstTax.tax_percent / 100)) : 0;

  const totalTaxAmount = vatTaxAmount + gstTaxAmount;
  return totalTaxAmount;
};


const couponAmount = couponData
  ? (calculateTotalPriceAfterDiscountAndCoupon() * (couponData.amount / 100)).toFixed(0)
  : 0;

   const usertoken = localStorage.getItem('token');
  if (!usertoken) {
    return <Navigate to="/Login" />;
  }

  return (
     
   <div className="main_wrap">   

     <Header />

     <div className="content-wrapper">           
         
            <div className="breadcrumb-wrap bg-f br-2">
               <div className="container">
                  <div className="breadcrumb-title text-center">
                     <h2>Guardare</h2>
                     <ul className="breadcrumb-menu list-style">
                        <li><a href="/">Casa </a></li>
                        <li>Guardare</li>
                     </ul>
                  </div>
               </div>
            </div>

             <section id="tour_booking_submission" className="section_padding pb-75 pt-100">
                 <div className="container">
                     <div className="row">
                     <div className="col-lg-12">
                     <div className="tour_detail_right_sidebar">
                                 <div className="tour_details_right_boxed eraw">
                                     <div className="tour_package_details_bar_list">
                                         <h5>Riepilogo prenotazione</h5>
                                          {packageData && packageData.property_service && (
                                            <ul>
                                              <li className="checkout_property">
                                                <div className="checkout_property_img">
                                                    <img src={packageData.property.thumb_image} alt={packageData.property.property_name} />
                                                </div>
                                                <div className="checkout_property_content">
                                                    <h5>{packageData.property.property_name}</h5>
                                                    <p>{packageData.property.address}</p>
                                                    <p className="property-price-chekout">€{packageData.property.price}/<span>gg</span></p>            
                                                </div>  
                                                                                    
                                              </li>                                                  
                                            </ul>
                                          )}
                                       </div>
                                 </div>
                             </div>
                      </div>

                      <div className="col-lg-12">
  <div className="tour_detail_right_sidebar vds extra-services">
    <div className="col-lg-12 col-md-6">
      <h5 className="mb-3">Seleziona Servizi</h5>
    </div>
    <div className="col-lg-12 col-md-6">
      {extraServices.map((extraService) => (
        <div
          className={`tour-detail-card mb-3 ${
            selectedExtraServices.includes(extraService.service_id) ? 'selected-q' : ''
          }`}
          key={extraService.service_id}
        >
          <div className="row g-0">
            {/* Service Image */}
            <div className="col-lg-3 col-12 position-relative">
              <img
                src={`https://dayoffice24.it/backend/images/${extraService.service.icon}`}
                className="img-fluid lounge-image rounded-start"
                alt={extraService.service.service_name}
              />
              <div className="position-absolute top-right-checkbox">
                <div
                  className={
                    selectedExtraServices.includes(extraService.service_id) ? 'service-selected' : 'tre'
                  }
                >
                  <i className="ri-check-line"></i>
                </div>
              </div>
            </div>

            {/* Service Details */}
            <div className="col-lg-7 col-12">
              <div className="tour-card-body">
                <h5 className="tour-card-title">{extraService.service.service_name}</h5>
                <p className="tour-card-text">{extraService.service.desc}</p>
                <ul className="tour-list-inline">
                  {extraService.service.extra_services.length > 0 ? (
                    extraService.service.extra_services.map((extra) => (
                      <li className="tour-list-inline-item" key={extra.description}>
                        <img
                          src={`https://dayoffice24.it/backend/images/${extra.icon}`}
                          alt={extra.description}
                        />{" "}
                        {extra.description}
                      </li>
                    ))
                  ) : (
                    <li>Nessun servizio extra disponibile</li>
                  )}
                </ul>
              </div>
            </div>

            {/* Service Pricing and Selection */}
            <div className="col-lg-2 col-12">
              <div className="pricandbook">
                <div className="pric-w">
                  <h5 className="tour-price">
                    <span>For just</span>€{extraService.service.price}
                  </h5>
                  <small>per person</small>
                </div>
                <button
                  className="btn btn-danger btn-sm tour-btn"
                  onClick={() => handleExtraServiceChange(extraService.service_id)}
                >
                  {selectedExtraServices.includes(extraService.service_id) ? 'Remove Service' : 'Add Service'}{" "}
                  <i
                    className={
                      selectedExtraServices.includes(extraService.service_id)
                        ? "ri-close-circle-line"
                        : "ri-add-circle-line"
                    }
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* Special Requests Section */}
    <div className="col-lg-12 col-md-6">
      <div className="tour_package_details_bar_list with_checkbox_list">
        <h5>Richieste speciali</h5>
        <p>
          Le richieste speciali non possono essere garantite, ma la struttura farà
          del suo meglio per soddisfare le vostre esigenze. Potete sempre fare una
          richiesta speciale dopo aver completato la prenotazione!
        </p>
        <div>
          <textarea
            className="form-control textarea_big_request"
            value={specialRequest}
            onChange={(e) => setSpecialRequest(e.target.value)}
          />
        </div>
      </div>
    </div>
  </div>
</div>

                         <div className="col-lg-8">

                            

                             <div className="tou_booking_form_Wrapper">
                                 <div className="booking_tour_form">
                                      <h5 className="heading_theme">Dettagli di fatturazione</h5>
                                          <div className="tour_booking_form_box">
                                          <form id="tour_bookking_form_item" onSubmit={handlePayNowClick}>
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input required
                                                      type="text"
                                                      className="form-control bg_input"
                                                      placeholder="Nome*"
                                                      name="first_name"
                                                      value={firstNameValue}
                                                      onChange={(e) => setFirstNameValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input required
                                                      type="text"
                                                      className="form-control bg_input"
                                                      placeholder="Cognome*"
                                                      name="last_name"
                                                      value={lastNameValue}
                                                      onChange={(e) => setLastNameValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input
                                                      type="text"
                                                      className="form-control bg_input"
                                                      placeholder="Nome della ditta"
                                                      name="company_name"
                                                      value={companyNameValue}
                                                      onChange={(e) => setCompanyNameValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input required
                                                      type="email"
                                                      className="form-control bg_input"
                                                      placeholder="Indirizzo e-mail*"
                                                      name="email_address"
                                                      value={emailValue}
                                                      onChange={(e) => setEmailValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input required
                                                      type="tel"
                                                      className="form-control bg_input"
                                                      placeholder="Numero di cellulare*"
                                                      name="mobile_no"
                                                      value={mobileValue}
                                                      onChange={(e) => setMobileValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input
                                                      type="text"
                                                      className="form-control bg_input"
                                                      placeholder="PEC"
                                                      name="pec"
                                                      value={pecValue}
                                                      onChange={(e) => setPecValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input
                                                      type="text"
                                                      className="form-control bg_input"
                                                      placeholder="SDI"
                                                      name="sdi"
                                                      value={sdiValue}
                                                      onChange={(e) => setSdiValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input
                                                      type="text"
                                                      className="form-control bg_input"
                                                      placeholder="Codice Fiscale / P.iva"
                                                      name="tax_id_code"
                                                      value={taxidValue}
                                                      onChange={(e) => setTaxIDValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input required
                                                      type="text"
                                                      className="form-control bg_input"
                                                      placeholder="indirizzo*"
                                                      name="address"
                                                      value={addressValue}
                                                      onChange={(e) => setAddressValue(e.target.value)}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-12">
                                                  <div className="form-group">
                                                    <input required
                                                      type="text"  
                                                      className="form-control bg_input"
                                                      placeholder="Città*"
                                                      name="state"
                                                      value={stateValue}
                                                      onChange={(e) => setStateValue(e.target.value)}
                                                    />        
                                                  </div>
                                                </div>
                                                <div className="col-lg-12">  
                                                  <div className="form-group">
                                                    <input required
                                                      type="text"  
                                                      className="form-control bg_input"
                                                      placeholder="Provincia*"
                                                      name="country"
                                                      value={countryValue}
                                                      onChange={(e) => setCountryValue(e.target.value)}
                                                    />         
                                                  </div>
                                                </div>
                                              </div>                                         
                                            <input
                                              type="hidden"
                                              name="property_id"
                                              value={propertyId}
                                            />
                                            <input
                                              type="hidden"
                                              name="start_date"
                                              value={startDate} 
                                            />
                                            <input
                                              type="hidden"
                                              name="end_date"
                                              value={endDate} 
                                            />
                                            <input
                                              type="hidden"
                                              name="property_amount"
                                              value={propertyPricePerDay} 
                                            />
                                            <input
                                              type="hidden"
                                              name="discount_amount"
                                              value={discountAmount} 
                                            />
                                            <input
                                              type="hidden"
                                              name="service_id[]"
                                              value={selectedExtraServices.join(',')} 
                                            />
                                            <input
                                              type="hidden"
                                              name="coupon_id"
                                              value={couponData ? couponData._id : ''}
                                            />
                                            <input
                                              type="hidden"
                                              name="total_amount"
                                              value={calculateTotalPriceAfterDiscountAndCoupon().toFixed(0)}
                                            />
                                            <input
                                              type="hidden"
                                              name="coupon_amount"
                                              value={couponAmount}
                                            />

                                            <input
                                                  type="hidden"
                                                  name="tax_id[]"
                                                  value={taxData.map(tax => tax._id).join(',')} 
                                                />

                                            <input
                                              type="hidden"
                                              name="total_tax"
                                              value={calculateTotalTaxAmount().toFixed(0)} 
                                            />
                                            <input
                                              type="hidden"
                                              name="final_total"
                                              value={(
                                                    calculateTotalPriceAfterDiscountAndCoupon() *
                                                    (1 + calculateTotalTaxPercentage())
                                                  ).toFixed(0)}
                                            />
                                            <input
                                              type="hidden"
                                              name="first_name"
                                              value={firstNameValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="last_name"
                                              value={lastNameValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="company_name"
                                              value={companyNameValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="email"
                                              value={emailValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="mobile_no"
                                              value={mobileValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="address"
                                              value={addressValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="state"
                                              value={stateValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="country"
                                              value={countryValue}
                                            />
                                            <input
                                              type="hidden"
                                              name="pec"
                                              value={pecValue} 
                                            />
                                            <input
                                              type="hidden"
                                              name="sdi"
                                              value={sdiValue} 
                                            />
                                            <input
                                              type="hidden"
                                              name="tax_id_code"
                                              value={taxidValue} 
                                            />
                                            <div className="book_now_btn text-center">
                                                <button className="btn style1" type="submit">
                                                  Prenota ora
                                                </button>
                                            </div>
                                          </form>       
                                          </div>
                                    </div>
                             </div>
                         </div>
                         <div className="col-lg-4">
                             <div className="tour_details_right_sidebar_wrapper vds">                                 
                                 
                               

                                 <div className="tour_detail_right_sidebar">
                                  <div className="tour_details_right_boxed">
                                    <div className="tour_package_details_bar_list with_checkbox_list">
                                      <h5>codice coupon</h5>
                                      <div>
                                        <input
                                          type="text"
                                          className="form-control coupon_code bg_input"
                                          placeholder="codice coupon"
                                          value={couponCode}
                                          onChange={(e) => setCouponCode(e.target.value)}
                                        />

                                        {couponError && (
                                          <div className="coupon-error-message">
                                            {couponError}
                                          </div>
                                        )}

                                        {couponApplied && !couponError && (
                                          <div className="coupon-success-message">
                                            Codice coupon applicato con successo!
                                          </div>
                                        )}

                                        <button className="btn style1" type="button" onClick={applyCoupon}>
                                          Applica
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>



                                <div className="tour_detail_right_sidebar"> 
                                      <div className="tour_details_right_boxed black_background">
                                        <div className="tour_details_right_box_heading">
                                          <h3>Importo della prenotazione</h3> 
                                        </div>
                                        <div className="tour_booking_amount_area">
                                            
                                            <ul>
                                              <li>
                                                {packageData && packageData.property ? (
                                                  <>
                                                    {packageData.property.property_name} x {calculateDurationInDays(startDate, endDate)} Giorni x {numberOfGuests} Ospiti
                                                    <span className="t_amount">€{(propertyPricePerDay * calculateDurationInDays(startDate, endDate) * numberOfGuests).toFixed(0)}</span>
                                                  </>
                                                ) : (
                                                  <p>Nessun dato sul pacchetto disponibile</p>
                                                )}
                                              </li>
                                            </ul>

                                            {discountAmount > 0 && (
                                              <ul>
                                                <li>
                                                  Sconto
                                                  <span>€{discountAmount.toFixed(0)}</span>
                                                </li>
                                              </ul>
                                            )}  

<ul className="services_list">
  {selectedServicesDetails.map((service) => {
    // Calculate the total service price based on the number of persons
    const totalServicePrice = parseFloat(service.servicePrice) * numberOfGuests;

    return (
      <li key={service.serviceId}>
        {service.serviceName}
        <span>€{totalServicePrice.toFixed(0)}</span>
      </li>
    );
  })}
</ul>


 
                                          {couponData && (
                                              <ul>
                                                <li>
                                                  Coupon Applicato: {couponData.coupon_code}
                                                  <span>
                                                    {couponData.amount_type === 'percentage'
                                                      ? `${couponData.amount}%`
                                                      : `$${couponData.amount}`}
                                                  </span>
                                                </li>
                                              </ul>
                                            )}
                                   

                                          <div className="total_subtotal_booking">
                                            <h6>Importo netto <span>€{calculateTotalPriceAfterDiscountAndCoupon().toFixed(0)}</span> </h6>
                                          </div>

                                         <div className="total_subtotal_booking">
                                              {taxData.find(tax => tax.tax_type === 'VAT') && (
                                                <h6>
                                                  Imposta <span>{taxData.find(tax => tax.tax_type === 'VAT').tax_percent}%</span>
                                                </h6>
                                              )}
                                            </div>
                                            <div className="total_subtotal_booking">
                                              {taxData.find(tax => tax.tax_type === 'GST') && (
                                                <h6>
                                                  GST Imposta <span>{taxData.find(tax => tax.tax_type === 'GST').tax_percent}%</span>
                                                </h6>
                                              )}
                                            </div>
                                            <div className="total_subtotal_booking">
                                              <h6>
                                               Importo totale{" "}
                                                <span>
                                                  €{(
                                                    calculateTotalPriceAfterDiscountAndCoupon() *
                                                    (1 + calculateTotalTaxPercentage())
                                                  ).toFixed(2)}
                                                </span>{" "}
                                              </h6>
                                            </div>
                                        </div>

                                        </div>                                        
                                     
                                    </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </section>
     </div>

     <Footer />  

    </div>

  );
};

export default Checkout;
