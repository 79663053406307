import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import listing_shape from "../assets/images/add-listing-shape.png";
import city_shape from "../assets/images/shape-2_1.png";
import ProductCarousel from "../components/ProductCarousel";
import TestimonialsCarousel from "../components/TestimonialsCarousel";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallAction from "../components/CallAction";
import { Helmet } from "react-helmet";

const Home = () => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [h1Title, setH1Title] = useState("");
  const [propertyData, setPropertyData] = useState([]);
  const [sectionHeading, setSectionHeading] = useState("");
  const [sectionParagraph, setSectionParagraph] = useState("");
  const [aboutData, setAboutData] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [sectionData, setSectionData] = useState(null);
  const [sectionDatatest, setSectionDatatest] = useState(null);
  const [searchFormData, setSearchFormData] = useState({
    search_input: "",
    start_date: "",
    end_date: "",
    no_of_guest: "0",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchFormData({
      ...searchFormData,
      [name]: value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    window.location.href = `/PropertySearch?search_input=${searchFormData.search_input}&start_date=${searchFormData.start_date}&end_date=${searchFormData.end_date}&no_of_guest=${searchFormData.no_of_guest}`;
  };

  useEffect(() => {
    // Fetch data from the API
    fetch("https://dayoffice24.it/backend/api/section/1")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.success) {
          setBackgroundImage(data.data.photo);
          setH1Title(data.data.title);
        } else {
          throw new Error("API returned an error: " + data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching section data:", error);
      });

    // Fetch property data from the API
    fetch("https://dayoffice24.it/backend/api/recommended-property")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Log the API response to check its format
        if (data.success) {
          setPropertyData(data.data); // Assuming the property data is an array of objects
        } else {
          throw new Error("API returned an error: " + data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching property data:", error);
      });

    // Fetch data from the API
    fetch("https://dayoffice24.it/backend/api/section/2")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Log the API response to check its format
        if (data.success) {
          setSectionHeading(data.data.title);
          setSectionParagraph(data.data.short_desc);
        } else {
          throw new Error("API returned an error: " + data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching section data:", error);
      });
    fetch("https://dayoffice24.it/backend/api/section/3")
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setAboutData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching about data:", error);
      });

    // Fetch city data from the API
    fetch("https://dayoffice24.it/backend/api/city-section")
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setCityData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });

    // Fetch top heading and subheading data from the API
    // fetch('https://dayoffice24.it/backend/api/section/4')
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.success && data.data) {
    //       setSectionData(data.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching section data:', error);
    // });

    // Fetch section data from the API
    fetch("https://dayoffice24.it/backend/api/section/5")
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setSectionDatatest(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching section data:", error);
      });
  }, []);

  const [seoDetails, setSeoDetails] = useState({
    title: "",
    description: "",
    keywords: "",
  });

  useEffect(() => {
    fetch("https://dayoffice24.it/backend/api/seo-details/home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setSeoDetails({
            title: data.data.page_title,
            description: data.data.meta_desc,
            keywords: data.data.keywords,
          });
        } else {
          throw new Error("API returned an error: " + data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching SEO data:", error);
      });
  }, []);

  const [showModal, setShowModal] = useState(false);

  // Toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [section7Data, setSection7Data] = useState(null); // State for section 7 (Gestisci e Prenota Spazi di Lavoro)

  useEffect(() => {
    // Fetch data for Section 7 (Gestisci e Prenota Spazi di Lavoro)
    fetch("https://dayoffice24.it/backend/api/section/7")
      .then((response) => response.json())
      .then((data) => {
        console.log("Section 7 Data:", data); // Log to check structure
        if (data.success && data.data) {
          setSection7Data(data.data); // Set the fetched data for this section
        } else {
          throw new Error("API returned an error: " + data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching Section 7 data:", error);
      });
  }, []); // Empty dependency array to run only once on mount

  // Path for images
  const imagePath = "https://dayoffice24.it/backend/images/"; // Prepend image path with this URL

  const [workspaceData, setWorkspaceData] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://dayoffice24.it/backend/api/section/8")
      .then((response) => response.json())
      .then((data) => {
        if (data.success && !data.error) {
          setWorkspaceData(data.data);
        }
      })
      .catch((error) => console.error("Error fetching workspace data:", error));
  }, []);

  const [trqsection, setTrqsection] = useState({});

  useEffect(() => {
    fetch("https://dayoffice24.it/backend/api/section/5")
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setTrqsection(result.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

    // State to store the API data
    const [workSectionData, setWorkSectionData] = useState({}); // Initialized with an empty object
  
    // Fetch data from the API when the component mounts
    useEffect(() => {
      fetch('https://dayoffice24.it/backend/api/section/4')
        .then((response) => response.json())
        .then((data) => {
          if (data.success && data.data) {
            setWorkSectionData(data.data); // Set the data from API
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    }, []);

  return (
    <div className="main_wrap">
      <Helmet>
        <title>{seoDetails.title} - HRD Group Srl S.</title>
        <meta name="description" content={seoDetails.description} />
        <meta name="keywords" content={seoDetails.keywords} />
      </Helmet>

      <Header />

      <section className="hero-wrap style2 border_remove_hero">
        <div className="container-fluid14">
          <div className="hero-slider-one">
            <div
              className="hero-slide-item bg-f"
              style={{ backgroundImage: `url(${backgroundImage})` }}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-md-8 col-sm-10">
                    <div className="hero-content text-center">
                      <h1>{h1Title}</h1>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-8 col-sm-10">
                    <div
                      className="search-property style1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      data-aos-delay="250"
                    >
                      <form
                        onSubmit={handleFormSubmit}
                        className="property-search-form"
                      >
                        <div className="form-group">
                          <input
                            type="text"
                            name="search_input"
                            required
                            value={searchFormData.search_input}
                            onChange={handleInputChange}
                            placeholder="Dove vuoi andare?"
                          />
                        </div>
                        <div className="form-group">
                        <input
  type="text" // Start as text to show placeholder
  name="start_date"
  required
  value={searchFormData.start_date}
  onChange={handleInputChange}
  placeholder="dd/mm/yyyy" // Placeholder for mobile devices
  onFocus={(e) => (e.target.type = "date")} // Switch to date picker
  onBlur={(e) => {
    if (!e.target.value) e.target.type = "text"; // Restore placeholder if blank
  }}
  className="custom-date-input"
/>
                        </div>
                        <div className="form-group">
                         
<input
  type="text" // Start as text to show placeholder
  name="end_date"
  required
  value={searchFormData.end_date}
  onChange={handleInputChange}
  placeholder="dd/mm/yyyy" // Placeholder for mobile devices
  onFocus={(e) => (e.target.type = "date")} // Switch to date picker
  onBlur={(e) => {
    if (!e.target.value) e.target.type = "text"; // Restore placeholder if blank
  }}
  className="custom-date-input"
/>
                        </div>
                        <div className="form-group">
                          <select
                            name="no_of_guest"
                            id="guestCount"
                            value={searchFormData.no_of_guest}
                            onChange={handleInputChange}
                          >
                            <option value="0" data-display="Price Range">
                              Numero Ospiti
                            </option>
                            <option value="1">1 Ospite</option>
                            <option value="2">2 Ospite</option>
                            <option value="3">3 Ospite</option>
                            <option value="4">4 Ospite</option>
                            <option value="5">5 Ospite</option>
                            <option value="6">6 Ospite</option>
                            <option value="7">7+ Ospite</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <button type="submit" className="btn style2">
                            Cerca DayOffice
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="property-wrap ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div className="section-title style1 text-center mb-40">
                <h2>{sectionHeading}</h2>
                <p>{sectionParagraph}</p>
              </div>
            </div>
          </div>
          <div className="property-slider-two owl-carousel slide">
            <ProductCarousel propertyData={propertyData} />
          </div>
        </div>
      </section>

      {/* Section 7: Gestisci e Prenota Spazi di Lavoro */}
      {section7Data && (
        <section className="learn-more ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
                <div className="section-title style1 text-center mb-40">
                  <h2>{section7Data.title}</h2>
                  <p>{section7Data.short_desc}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="learn-inner">
                  <h4 className="subtitle subtitle--small">
                    Register{" "}
                    <span className="line line--subtitle line--subtitle-vision"></span>
                  </h4>
                  <h2>{section7Data.heading_1}</h2>
                  <div className="learn-img-sc">
                    <div className="learn-img">
                      <img
                        src={`${imagePath}${section7Data.image_1}`}
                        alt="Register Your Property"
                      />
                    </div>
                    <div className="learn-button">
                      <a href="/BecomeHost">
                        <span>SCOPRI DI +</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="learn-inner">
                  <h4 className="subtitle subtitle--small">
                    Support{" "}
                    <span className="line line--subtitle line--subtitle-vision"></span>
                  </h4>
                  <h2>{section7Data.heading_2}</h2>
                  <div className="learn-img-sc">
                    <div className="learn-img">
                      <img
                        src={`${imagePath}${section7Data.image_2}`}
                        alt="Do you need support?"
                      />
                    </div>
                    <div className="learn-button">
                      <a href="/Contact">
                        <span>Contattaci</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="learn-inner">
                  <h4 className="subtitle subtitle--small">
                    Book{" "}
                    <span className="line line--subtitle line--subtitle-vision"></span>
                  </h4>
                  <h2>{section7Data.heading_3}</h2>
                  <div className="learn-img-sc">
                    <div className="learn-img">
                      <img
                        src={`${imagePath}${section7Data.image_3}`}
                        alt="Book Your Workspace"
                      />
                    </div>
                    <div className="learn-button">
                      <a className="" onClick={toggleModal}>
                        <span>Prenota ora</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {showModal && (
        <div
          className="modal fade show "
          tabIndex="-1"
          style={{ display: "block" }}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={toggleModal}
              ></button>
              <div className="modal-body">
                <section className="workspace-section" id="workspace-s">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
                        <div className="section-title style1 text-center mb-40">
                          <h2>{workspaceData.title}</h2>
                          <p>{workspaceData.short_desc}</p>
                        </div>
                      </div>
                    </div>

                    <div className="row g-4">
                      {/* Private Offices Card */}
                      <div className="col-md-4">
                        <div className="card custom-card">
                          <img
                            src={`${imagePath}${workspaceData.image_1}`}
                            className="card-img-top private-card-img"
                            alt="Private Office"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              {workspaceData.title_1}
                            </h5>
                            <p className="card-text">
                              {workspaceData.short_desc_1}
                            </p>
                            <ul className="list-unstyled">
                              <li>
                                <i className="ri-calendar-line"></i>{" "}
                                {workspaceData.heading_1}
                              </li>
                              <li>
                                <i className="ri-group-line"></i>{" "}
                                {workspaceData.heading_2}
                              </li>
                            </ul>
                            <div className="d-flex ads">
                              <a className="btn style2" href="https://dayoffice24.it/category/65fd1b02358f0d7ac102aa5f">
                                Prenota Ora
                              </a>
                              <a className="btn style3" href="/contact">
                                Richiedi Preventivo
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Custom Offices Card */}
                      <div className="col-md-4">
                        <div className="card custom-card">
                          <img
                            src={`${imagePath}${workspaceData.image_2}`}
                            className="card-img-top custom-card-img"
                            alt="Custom Office"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              {workspaceData.title_2}
                            </h5>
                            <p className="card-text">
                              {workspaceData.short_desc_2}
                            </p>
                            <ul className="list-unstyled">
                              <li>
                                <i className="ri-calendar-check-line"></i>{" "}
                                {workspaceData.heading_3}
                              </li>
                              <li>
                                <i className="ri-group-line"></i>{" "}
                                {workspaceData.heading_4}
                              </li>
                            </ul>
                            <div className="d-flex ads">
                              <a className="btn style2" href="https://dayoffice24.it/category/65fd1b02358f0d7ac102aa5f">
                                Prenota Ora
                              </a>
                              <a className="btn style3" href="/contact">
                                Richiedi Preventivo
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Day Offices Card */}
                      <div className="col-md-4">
                        <div className="card custom-card">
                          <img
                            src={`${imagePath}${workspaceData.image_3}`}
                            className="card-img-top custom-card-img"
                            alt="Day Office"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              {workspaceData.title_3}
                            </h5>
                            <p className="card-text">
                              {workspaceData.short_desc_3}
                            </p>
                            <ul className="list-unstyled">
                              <li>
                                <i className="ri-calendar-check-line"></i>{" "}
                                {workspaceData.heading_5}
                              </li>
                              <li>
                                <i className="ri-group-line"></i>{" "}
                                {workspaceData.heading_6}
                              </li>
                            </ul>
                            <div className="d-flex ads">
                              <a className="btn style2" href="https://dayoffice24.it/category/65fd1b02358f0d7ac102aa5f">
                                Prenota Ora
                              </a>
                              <a className="btn style3" href="/contact">
                                Richiedi Preventivo
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}

<section className="business-section ptb-100 sec-on work-where-sec">
      <div className="container">
        <div className="row g-4">
          {/* Image Section */}
          <div className="col-md-6">
            <div className="business-card">
              <img
                src={workSectionData.photo || '/placeholder.jpg'} // Fallback image if no data
                className="business-card-img"
                alt="Work Location"
              />
            </div>
          </div>

          {/* Text Content Section */}
          <div className="col-md-6">
            <div className="card custom-card">
              <div className="card-body">
                <h2 className="ci-title">
                  {workSectionData.title || 'Default Title'} {/* Fallback title */}
                </h2>
                <ul className="list-unstyled">
                  <li>
                    <i className="ri-calendar-check-line"></i>{' '}
                    {workSectionData.short_desc || 'Default Description'}
                  </li>
                  <li>
                    <i className="ri-group-line"></i>{' '}
                    {workSectionData.short_desc_2 || 'Additional Info'}
                  </li>
                </ul>
                <div className="d-flex ads">
                  <a className="btn style2" href="/contact">
                    Contattaci Ora!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

      <section className="business-section pb-100 sdfwv sec-details">
        <div className="container">
          <div className="row g-4">
            {/* Content Section */}
            <div className="col-md-6">
              <div className="card custom-card">
                <div className="card-body">
                  {/* Dynamic Title */}
                  <h2 className="ci-title">{trqsection.title}</h2>

                  <ul className="list-unstyled">
                    {/* Dynamic Descriptions */}
                    <li>
                      <i className="ri-calendar-check-line"></i>
                      {trqsection.short_desc}
                    </li>
                    <li>
                      <i className="ri-group-line"></i>
                      {trqsection.short_desc_2}
                    </li>
                  </ul>

                  <div className="d-flex ads">
                    <a className="btn style2" href="/contact">
                      Contattaci Ora!
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Dynamic Image Section */}
            <div className="col-md-6">
              <div className="business-card text-right">
                <img
                  src={trqsection.photo}
                  className="business-card-img"
                  alt="Business Office"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrap gray_bg_updated style1 ptb-100 bg-seashell">
        <div className="container">
          {aboutData && (
            <div className="row gx-5 align-items-center bg-white_image">
              <div
                className="col-lg-8"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <div className="about_img">
                  <img src={aboutData.photo} alt="About" />
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <div className="about-content">
                  <div className="content-title style1">
                    <h2>{aboutData.title}</h2>
                    <p>{aboutData.short_desc}</p>
                  </div>
                  <Link to="/BecomeHost" className="btn style2">
                    Diventa Host
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
