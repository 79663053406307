import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import white_logo from '../assets/images/white_logo.png';

const Footer = () => {
  const [popularCities, setPopularCities] = useState([]);
  const [footerData, setFooterData] = useState(null);
  const [id,setId] = useState("");
  const [company_desc,setCompany_Desc] = useState("");
  const [company_logo,setCompany_Logo] = useState("");
  const [facebook_link,setFacebook_link] = useState("");
  const [twitter_link,setTwitter_link] = useState("");
  const [instagram_link,setInstagram_link] = useState("");
  const [linkedin_link,setLinkedin_link] = useState("");



  useEffect(() => {
   fetchFooterData();
 }, []);

 const fetchFooterData = async () => {
   try {
     const response = await fetch("https://dayoffice24.it/backend/api/footer");
     console.log(response, "response");
     const data = await response.json();
     console.log(data, "data");
      setId(data.data.id);
      setCompany_Desc(data.data.company_desc);
      setCompany_Logo(data.data.company_logo);
      setFacebook_link(data.data.facebook_link);
      setInstagram_link(data.data.instagram_link);
      setLinkedin_link(data.data.linkedin_link);
      setTwitter_link(data.data.twitter_link);
     setFooterData(data.data);
   } catch (error) {
     console.error("Error fetching Footer data:", error);
   }
 };

  useEffect(() => {
    fetch('https://dayoffice24.it/backend/api/popular-cities')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.success) {
          setPopularCities(data.data);
        } else {
          throw new Error('API returned an error:' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching popular cities:', error);
      });
  }, []);

  // Check if popularCities data is available
  if (popularCities.length === 0) {
    return <p>Loading...</p>; // Show a loading message while data is being fetched
  }


  return (    
      <footer className="footer-wrap style2 blck_footer pt-100">
            <div className="footer-top">
               <div className="container">
                  <div className="row pb-75">
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 pe-xl-5">
                        <div className="footer-widget">
                              <img className="logo-light" src={company_logo} alt="Image" />
                           <p className="comp-desc">
                              {company_desc}
                           </p>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 ervcx">
                        <div className="footer-widget mt-xl-5">
                           {/* <h3 className="footer-widget-title">Città popolari</h3> */}
                            <ul className="footer-menu list-style">
                            <li>
                                 <a href="/About">
                                 <i className="ri-arrow-right-s-line"></i>
                                 Chi siamo
                                 </a>
                              </li>
                              <li>
                                 <a href="/HelpCenter">
                                 <i className="ri-arrow-right-s-line"></i>
                                 Centro assistenza
                                 </a>
                              </li>
                              <li>
                                 <a href="/Contact">
                                 <i className="ri-arrow-right-s-line"></i>
                                 Contattaci
                                 </a>
                              </li>
                            </ul>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 ps-xl-5">
                        <div className="footer-widget mt-xl-5">
                           {/* <h3 className="footer-widget-title">Link veloci</h3> */}
                           <ul className="footer-menu list-style">
                              
                              <li>
                                 <a href="/Faq">
                                 <i className="ri-arrow-right-s-line"></i>
                                 FAQ
                                 </a>
                              </li>
                              <li>
                                 <a href="/Privacy">
                                 <i className="ri-arrow-right-s-line"></i>
                                 Privacy Policy
                                 </a>
                              </li>
                              <li>
                                 <a href="/Terms">
                                 <i className="ri-arrow-right-s-line"></i>
                                 Termini & Condizioni
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                     {/* <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <div className="footer-widget">
                            <h3 className="footer-widget-title">Sociale</h3>
                           <ul className="social-profile list-style style1">
                               <li>
                                  <a target="_blank" href={facebook_link}>
                                  <i className="ri-facebook-fill"></i>
                                  </a>
                               </li>
                               <li>
                                  <a target="_blank" href={twitter_link}>
                                  <i className="ri-twitter-fill"></i>
                                  </a>
                               </li>
                               <li>
                                  <a target="_blank" href={instagram_link}>
                                  <i className="ri-instagram-line"></i>
                                  </a>
                               </li>
                               <li>
                                  <a target="_blank" href={linkedin_link}>
                                  <i className="ri-linkedin-fill"></i>
                                  </a>
                               </li>
                            </ul>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
            <div className="container">
               <div className="footer-bottom">
                  <div className="row align-items-center">
                     <div className="col-md-12">
                        <p className="copyright-text">
                           <i className="ri-copyright-line"></i>HRD Group Srl S. © 2023. Tutti i diritti riservati.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </footer>

  );
};

export default Footer;
